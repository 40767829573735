<script>
  import { ValidationProvider } from 'vee-validate'
  import { IMaskComponent } from 'vue-imask'

  /**
   * A form control that accepts data input of some type (text, email, password, etc.).
   */
  export default {
    components: {
      ValidationProvider,
      IMaskComponent,
    },
    provide() {
      return {
        BaseInput: this,
      }
    },
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      /** The input’s type. */
      type: {
        type: String,
        required: true,
        validator: (value) =>
          ['text', 'email', 'password', 'search', 'tel', 'url', 'textarea', 'select'].includes(
            value
          ),
      },
      /** The input’s name, for validation purposes. */
      name: {
        type: String,
        default: undefined,
      },
      /** The input’s value. */
      value: {
        type: [String, Number],
        default: '',
      },
      /** Only if `type` is `select`. An array of options to choose from. Can be an array of strings, or an array of objects containing `label` and `value` keys. */
      options: {
        type: Array,
        default: () => [],
      },
      /** Whether the input is disabled. A disabled input is grayed out and cannot be focused. */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Whether the input is required. A required input will fail validation if it is empty. */
      required: {
        type: Boolean,
        default: false,
      },
      /** The minimum number of characters the value must have to pass validation. Does not apply if `type` is `select`. */
      minLength: {
        type: Number,
        default: undefined,
      },
      /** The maximum number of characters the value must have to pass validation. Also, the browser will not let the user enter a longer value. Does not apply if `type` is `select`. */
      maxLength: {
        type: Number,
        default: undefined,
      },
      /** The regular expression the value must match to pass validation. Does not apply if `type` is `textarea` or `select`. */
      pattern: {
        type: RegExp,
        default: undefined,
      },
      /** Whether there has to be a password pattern to match. */
      passwordPattern: {
        type: Boolean,
        default: false,
      },
      /** Any validation rules accepted by VeeValidate, using the object syntax (see https://logaretm.github.io/vee-validate/advanced/rules-object-expression.html). No need to specify `required`, `min`, `max`, or `regex` rules if the `required`, `minLength`, `maxLength`, or `pattern` props are used, respectively. */
      rules: {
        type: Object,
        default: undefined,
      },
      /** Custom validation messages, keyed by rule name. */
      customMessages: {
        type: Object,
        default: undefined,
      },
      /** Any mask value accepted by imaskjs (see https://imask.js.org). Does not apply if `type` is `select`. */
      mask: {
        type: undefined,
        default: undefined,
      },
      /** Only if `mask` is used. Whether the `value` accepted by this component and the value of the `input` event it emits exclude the mask. */
      valueIsUnmasked: {
        type: Boolean,
        default: false,
      },
      /** Only if `mask` is used. Any definitions value accepted by imaskjs (see https://imask.js.org). */
      maskDefinitions: {
        type: Object,
        default: undefined,
      },
      /** Only if `mask` is used. Any blocks value accepted by imaskjs (see https://imask.js.org). */
      maskBlocks: {
        type: Object,
        default: undefined,
      },
      /** Only if `type` is `textarea`. Whether the textarea can be resized vertically by the user. */
      resizable: {
        type: Boolean,
        default: false,
      },
      /** Classes to apply to the `input` or `textarea` element. */
      inputClasses: {
        type: String,
        default: undefined,
      },
      /** Whether the input label is multi-line. This prop allows the text position of multi-line inputs to be centered. */
      multiLineLabel: {
        type: Boolean,
        default: false,
      },
      /** Whether to keep the label small and at the top of the input even when the value is empty, in case there’s an actual placeholder (e.g., `+1 (###) ### - ####)` for phone number). */
      hasPlaceholder: {
        type: Boolean,
        default: false,
      },
      /** Whether this component is being used for the PDP selector. */
      isPdpSelector: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        focused: false,
        iconNodes: [],
        validationProviderRef: null,
        inputRef: null,
      }
    },
    computed: {
      component() {
        switch (this.type) {
          case 'select':
            return 'select'
          case 'textarea':
            return 'textarea'
          default:
            if (this.mask) {
              return 'IMaskComponent'
            }
            return 'input'
        }
      },
      innerValue: {
        get() {
          return this.value
        },
        set(value) {
          /** Emitted when the input’s value changes. */
          this.$emit('input', value)
        },
      },
      hasValue() {
        if (this.type === 'select') {
          return Boolean(this.selectedOption)
        }
        return Boolean(this.innerValue)
      },
      normalizedOptions() {
        return this.options.map((option) => {
          if (option && typeof option === 'object') {
            return option
          }
          return {
            label: option,
            value: option,
          }
        })
      },
      selectedOption() {
        return this.normalizedOptions.find((option) => this.innerValue === option.value)
      },
      selectedOptionLabel() {
        return this.selectedOption?.label ?? ''
      },
      labelIsSmall() {
        return this.hasValue || (this.type !== 'select' && this.focused) || this.hasPlaceholder
      },
      finalRules() {
        if (this.disabled) {
          return undefined
        }
        const rules = {}
        if (this.type === 'email') {
          rules.email = true
          rules.emailRegex = /^(?!.*con$).*$/
        }
        if (this.required) {
          rules.required = true
        }
        if (this.minLength !== undefined && this.minLength === this.maxLength) {
          rules.length = this.minLength
        } else {
          if (this.minLength !== undefined) {
            rules.min = this.minLength
          }
          if (this.maxLength !== undefined) {
            rules.max = this.maxLength
          }
        }
        if (this.pattern) {
          rules.regex = this.pattern
        }
        if (this.passwordPattern) {
          // THIS SHOULD MATCH BACKEND
          // checking for minimum of 8 characters and a combinations of letters and at least 1 number or special character
          rules.passwordRegex = /^(?=.*[A-Za-z])(?=.*[\d@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/
        }
        if (this.rules) {
          Object.assign(rules, this.rules)
        }
        if (Object.keys(rules).length === 0) {
          return undefined
        }
        return rules
      },
      iconCount() {
        let iconCount = this.iconNodes.length
        if (this.component === 'select') {
          iconCount++
        }
        if (this.validationProviderRef?.errors?.length > 0) {
          iconCount++
        }
        return iconCount
      },
      paddingRight() {
        if (this.iconCount === 0) {
          return 0
        }
        return this.$twToRem(4 + this.iconCount * 8)
      },
      listeners() {
        const { input, ...listeners } = this.$listeners
        return listeners
      },
    },
    mounted() {
      this.refreshIconNodes()
    },
    methods: {
      focus() {
        if (!this.inputRef) {
          return
        }
        let inputElement = this.inputRef.$el ?? this.inputRef
        if (inputElement && !inputElement.focus) {
          inputElement = inputElement.querySelector('input')
        }
        if (inputElement) {
          inputElement.focus()
        }
      },
      refreshIconNodes() {
        this.$skipNextTick(() => {
          this.iconNodes = this.$scopedSlots.icons?.()?.filter((node) => node.tag) ?? []
        })
      },
    },
  }
</script>

<template>
  <ValidationProvider
    v-slot="{ failed: validationFailed, ariaInput, errors }"
    v-ref="validationProviderRef"
    :name="name"
    :rules="finalRules"
    :custom-messages="customMessages"
    tag="div"
    :class="`font-normal text-base leading-snug ${disabled ? 'opacity-50' : ''}`"
  >
    <label v-updated="refreshIconNodes" class="block">
      <span class="block">
        <input
          v-if="component === 'input'"
          v-model="innerValue"
          v-ref="inputRef"
          :type="type"
          :disabled="disabled"
          :required="required"
          :minlength="minLength"
          :maxlength="maxLength"
          :pattern="pattern ? pattern.source : undefined"
          :class="`input input--text ${validationFailed ? 'input--invalid' : ''} ${inputClasses}`"
          :style="{ paddingRight: paddingRight }"
          v-bind="{ ...$attrs, ...ariaInput }"
          v-on="listeners"
          @focus="focused = true"
          @blur="focused = false"
        />
        <IMaskComponent
          v-else-if="component === 'IMaskComponent'"
          v-model="innerValue"
          v-ref="inputRef"
          :type="type"
          :disabled="disabled"
          :required="required"
          :mask="mask"
          :unmask="valueIsUnmasked"
          :definitions="maskDefinitions"
          :blocks="maskBlocks"
          :class="`input input--text ${validationFailed ? 'input--invalid' : ''} ${inputClasses}`"
          :style="{ paddingRight: paddingRight }"
          v-bind="{ ...$attrs, ...ariaInput }"
          v-on="listeners"
          @focus="focused = true"
          @blur="focused = false"
        />
        <textarea
          v-else-if="component === 'textarea'"
          v-model="innerValue"
          v-ref="inputRef"
          :disabled="disabled"
          :required="required"
          :minlength="minLength"
          :maxlength="maxLength"
          :class="`input input--textarea ${validationFailed ? 'input--invalid' : ''} ${
            !resizable ? 'resize-none' : ''
          } ${inputClasses}`"
          :style="{ paddingRight: paddingRight }"
          v-bind="{ ...$attrs, ...ariaInput }"
          v-on="listeners"
          @focus="focused = true"
          @blur="focused = false"
        />
        <span
          v-else-if="component === 'select'"
          :class="`input input--select ${isPdpSelector ? 'input--isPdpSelector' : ''} ${
            validationFailed ? 'input--invalid' : ''
          } ${inputClasses}`"
          :style="{ paddingRight: paddingRight }"
        >
          <span class="block truncate">
            {{ selectedOptionLabel }}
          </span>
          <select
            v-model="innerValue"
            v-ref="inputRef"
            :disabled="disabled"
            :required="required"
            class="absolute left-0 top-0 w-full h-full opacity-0"
            v-bind="{ ...$attrs, ...ariaInput }"
            v-on="listeners"
            @focus="focused = true"
            @blur="focused = false"
          >
            <option v-if="!hasValue" key="0" value="" selected disabled>
              <!-- @slot The input’s label. Should contain text. -->
              <slot />
            </option>
            <option
              v-for="(option, optionIndex) in normalizedOptions"
              :key="optionIndex + 1"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </span>
      </span>
      <span class="absolute inset-0 pointer-events-none">
        <span v-if="component === 'textarea'" class="absolute inset-x-0 top-0 m-1px h-6 bg-white" />
        <span
          :class="`absolute inset-x-0 mx-4 top-0 ${
            multiLineLabel ? 'mt-12px' : 'mt-18px'
          } p-1px text-gray-opacity70 truncate transform origin-left transition duration-200 ${
            labelIsSmall ? 'scale-85 -translate-y-4' : ''
          }`"
          :style="{ marginRight: paddingRight }"
        >
          <slot />
        </span>
      </span>
    </label>
    <span
      v-show="iconCount > 0"
      class="absolute right-0 mr-3 top-0 flex items-center h-15 text-gray-dark pointer-events-none"
    >
      <BaseIconInput
        v-if="errors.length > 0"
        data-cy="error-warning"
        :label="errors[0]"
        color="red"
      >
        <IconInfo />
      </BaseIconInput>
      <!-- @slot Optional. Icon(s) to display on the right end of the input. Should contain one or multiple `BaseIconInput` component(s). -->
      <slot name="icons" />
      <BaseIconInput v-if="component === 'select'" :color="isPdpSelector ? 'black' : 'gray'">
        <IconChevronDown />
      </BaseIconInput>
    </span>
    <div v-if="errors.length > 0" class="pt-1 pl-4 text-red text-sm md:text-base">{{
      errors[0]
    }}</div>
  </ValidationProvider>
</template>

<style lang="postcss" scoped>
  .input {
    @apply block w-full h-15 px-4 pt-6 rounded border border-gray-opacity68 transition duration-200 bg-dawn;

    &--text {
      @apply px-0 pt-3 indent-4;
    }

    &--isPdpSelector {
      @apply bg-transparent border-2 text-xl pt-0 flex items-center justify-center;
    }

    &--textarea {
      @apply h-30 pb-2;
    }

    &--invalid {
      @apply border-primary-dark;
    }

    &:focus,
    &:focus-within {
      @apply border-primary border-opacity-50 shadow-outline-primary-50;
    }
  }
</style>
